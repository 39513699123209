import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import * as styles from "../feature/noticeDetails/assets/css/noticeDetails.module.scss"

const PostDetails = ({ data }) => {
  return (
    <Layout>
      <h3>{data.allMicrocmsPost.edges[0].node.title}</h3>
      {data.allMicrocmsPost.edges[0].node.image1 && (
        <p>
          <img
            src={data.allMicrocmsPost.edges[0].node.image1.url}
            alt="画像の取得に失敗しました。"
          ></img>
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.content1 && (
        <p className={styles.content}>
          {data.allMicrocmsPost.edges[0].node.content1}
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.image2 && (
        <p>
          <img
            src={data.allMicrocmsPost.edges[0].node.image2.url}
            alt="画像の取得に失敗しました。"
          ></img>
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.content2 && (
        <p className={styles.content}>
          {data.allMicrocmsPost.edges[0].node.content2}
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.image3 && (
        <p>
          <img
            src={data.allMicrocmsPost.edges[0].node.image3.url}
            alt="画像の取得に失敗しました。"
          ></img>
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.content3 && (
        <p className={styles.content}>
          {data.allMicrocmsPost.edges[0].node.content3}
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.image4 && (
        <p>
          <img
            src={data.allMicrocmsPost.edges[0].node.image4.url}
            alt="画像の取得に失敗しました。"
          ></img>
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.content4 && (
        <p className={styles.content}>
          {data.allMicrocmsPost.edges[0].node.content4}
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.image5 && (
        <p>
          <img
            src={data.allMicrocmsPost.edges[0].node.image5.url}
            alt="画像の取得に失敗しました。"
          ></img>
        </p>
      )}
      {data.allMicrocmsPost.edges[0].node.content5 && (
        <p className={styles.content}>
          {data.allMicrocmsPost.edges[0].node.content5}
        </p>
      )}
    </Layout>
  )
}

export default PostDetails

export const query = graphql`
  query ($slug: String!) {
    allMicrocmsPost(filter: { id: { eq: $slug } }) {
      edges {
        node {
          title
          image1 {
            url
          }
          content1
          image2 {
            url
          }
          content2
          image3 {
            url
          }
          content3
          image4 {
            url
          }
          content4
          image5 {
            url
          }
          content5
        }
      }
    }
  }
`
